
import axios from 'axios'

// const API_URL = 'http://localhost:5000/api/transaction/';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });


const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}

const get_all_transactions = async () => {
    try {
        var res = await axiosInstance.get('/transaction/',getToken());
        console.log(res.data);
        return res;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        return res.data;
    }
}   
const get_transactions_by_criteria = async(criteria)=>{
    try {
        var res = await axiosInstance.post('/transaction/' + 'criteria',criteria,getToken());
        return res;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        return res.data;
    }
}

//trxn per current day,month,week and year
const get_dashboard_data = async()=>{
    try {
        var res = await axiosInstance.get('/transaction/dashboard',getToken());
        return res.data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        return res.data;
    }
}

const get_dashboard_data_per_agent_subagent = async(data)=>{
    try {
        var res = await axiosInstance.post('/transaction/dashboard_per_agent',data,getToken());
        return res.data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        return res.data;
    }
}

//trxn per current month per branch
const get_dashboard_data_by_branch = async()=>{
    try{
        var res = await axiosInstance.get('/transaction/dashboard/branch',getToken());
        return res.data;
    }catch(error){
        checkStatusAndRefreshPage(error);
        console.log(error);
        return res.data;
    }
}

const getTransactionDetailPerCompanyPerMonth = async(dateObj)=>{
    try{
        var res = await axiosInstance.post('/transaction/dashboard/perCompany',dateObj,getToken());
        return res.data;
    }catch(error){
        checkStatusAndRefreshPage(error);
        console.log(error);
        return res.data;
    }
}


const getAttendance = async(date)=>{
    try{
        var res = await axiosInstance.get('/transaction/attendance/' + date,getToken());
        return res.data;
    }catch(error){
        checkStatusAndRefreshPage(error);
        console.log(error);
        return res.data;
    }
}

const checkStatusAndRefreshPage = async(error)=>{
    try {
        if(error.response.status === 401){
            window.location.reload();
            localStorage.removeItem('bingo_user_info');
            localStorage.removeItem('bingo_access_token');
        }
    } catch (error) {
        
    }
}

const transactionListService = {
    get_all_transactions,
    get_transactions_by_criteria,
    get_dashboard_data,
    get_dashboard_data_by_branch,
    getTransactionDetailPerCompanyPerMonth,
    get_dashboard_data_per_agent_subagent,
    getAttendance
};
export default transactionListService;