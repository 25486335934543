import React,{useMemo,useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import './SelectedPatternDisplay.css'; // For CSS animations


  const SelectedPatternDisplay = () => {

    const [pattern, setPattern] = useState(0);
    const selectedPatternType = useSelector(state => state.transactionState.dynamic_pattern_one);
    /*useEffect(() => {
        const interval = setInterval(() => {
            setPattern(prevPattern => (prevPattern + 1) % 4);
        }, 2000); // Change pattern every 2 seconds

        return () => clearInterval(interval);
    }, []);*/
    //console.log(selectedPatternType);
    //const [selectedPatternType, setSelectedPatternType] = useState(patternSelected || 'oneLine');

  useEffect(() => {
    const interval = setInterval(() => {
        //console.log(selectedPatternType)
      if (selectedPatternType === null) {//oneLine
        setPattern(prevPattern => (prevPattern + 1) % 4); // Change between 0, 1, 2, 3 for one line patterns
      } else if (selectedPatternType === 149) {//twoLine
        setPattern(prevPattern => (prevPattern + 1) % 8); // Change between 0, 1, 2, 3, 4, 5 for two-line patterns
      }
      else if (selectedPatternType === 153) { //fourMiddleSingle
        setPattern(prevPattern => (prevPattern + 1) % 4); // Change between 0 to 3 for four corner square patterns
      }
      else if (selectedPatternType === 150) {//anyThreeLine
        setPattern(prevPattern => (prevPattern + 1) % 6); // Change between 0 to 5 for any three line patterns
      }
    }, 2000); // Change pattern every 2 seconds
    
    return () => clearInterval(interval);
  }, [selectedPatternType]);

    const getClassName = (index) => {
        const row = Math.floor(index / 5);
        const col = index % 5;
    
        if (selectedPatternType === null) {
          if (pattern === 0 && row === col) {
            return 'bingo-cell pattern-diagonal-1';
          } else if (pattern === 1 && row + col === 4) {
            return 'bingo-cell pattern-diagonal-2';
          } else if (pattern === 2 && row === 2) {
            return 'bingo-cell pattern-horizontal';
          } else if (pattern === 3 && col === 2) {
            return 'bingo-cell pattern-vertical';
          }
        } else if (selectedPatternType === 149) {//twoLine
            if (pattern === 0 && (row === 2 || row === col)) {
                return 'bingo-cell pattern-horizontal pattern-diagonal-1';
              } else if (pattern === 1 && (col === 2 || row === col)) {
                return 'bingo-cell pattern-vertical pattern-diagonal-1';
              } else if (pattern === 2 && (row === 2 || row + col === 4)) {
                return 'bingo-cell pattern-horizontal pattern-diagonal-2';
              } else if (pattern === 3 && (col === 2 || row + col === 4)) {
                return 'bingo-cell pattern-vertical pattern-diagonal-2';
              } else if (pattern === 4 && (row === col || row + col === 4)) {
                return 'bingo-cell pattern-diagonal-1 pattern-diagonal-2';
              } else if (pattern === 5 && (row === 2 || col === 2)) {
                return 'bingo-cell pattern-horizontal pattern-vertical';
              } else if (pattern === 6 && (row === 1 || row === 3)) {
                return 'bingo-cell pattern-horizontal';
              } else if (pattern === 7 && (col === 1 || col === 3)) {
                return 'bingo-cell pattern-vertical';
              }
        }
        else if (selectedPatternType === 153) {//fourMiddleSingle
            // Top-left corner square
            if (row === 1 && col === 1) {
              return 'bingo-cell pattern-four-corners';
            }
            // Top-right corner square
            else if (row === 1 && col === 3) {
              return 'bingo-cell pattern-four-corners';
            }
            // Bottom-left corner square
            else if (row === 3 && col === 1) {
              return 'bingo-cell pattern-four-corners';
            }
            // Bottom-right corner square
            else if (row === 3 && col === 3) {
              return 'bingo-cell pattern-four-corners';
            }
          }
          else if (selectedPatternType === 150) {//anyThreeLine
            // Three horizontal lines
            if (pattern === 0 && (row === 1 || row === 2 || row === 3)) {
              return 'bingo-cell pattern-horizontal';
            }
            // Three vertical lines
            else if (pattern === 1 && (col === 1 || col === 2 || col === 3)) {
              return 'bingo-cell pattern-vertical';
            }
            // Horizontal line and two vertical lines
            else if (pattern === 2 && ((row === 1 || row === 3) || col === 2)) {
              return 'bingo-cell pattern-horizontal pattern-vertical';
            }
            // Two horizontal lines and vertical line
            else if (pattern === 3 && ((row === 1 || row === 3) || col === 2)) {
              return 'bingo-cell pattern-horizontal pattern-vertical';
            }
            // Top-left to bottom-right diagonal and two vertical lines
            else if (pattern === 4 && ((row === col) || col === 2)) {
              return 'bingo-cell pattern-diagonal-1 pattern-vertical';
            }
            // Two horizontal lines and bottom-right to top-left diagonal
            else if (pattern === 5 && ((row === 4 || row === 2) || col === 2)) {
              return 'bingo-cell pattern-horizontal pattern-diagonal-2';
            }
          }
        return 'bingo-cell';
      };

    /*const getClassName = (index) => {
        
        const row = Math.floor(index / 5);
        const col = index % 5;

        if (pattern === 0 && row === col) {
            return 'bingo-cell pattern-diagonal-1';
        } else if (pattern === 1 && row + col === 4) {
            return 'bingo-cell pattern-diagonal-2';
        } else if (pattern === 2 && row === 2) {
            return 'bingo-cell pattern-horizontal';
        } else if (pattern === 3 && col === 2) {
            return 'bingo-cell pattern-vertical';
        }
        return 'bingo-cell';
    };*/

    const getClassNameForSelectedPattern = (index)=>{

    }

    return (
        <div className="bingo-p-board">
            {Array(25).fill(null).map((_, index) => (
                <div key={index} className={getClassName(index)}></div>
            ))}
        </div>
    );
  };

export default SelectedPatternDisplay;
