import axios from "axios";
// const API_URL = 'http://localhost:5000/api'


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}


const create_winning_transaction = async (game_data) => {
    //validate game_data client side
    //return error if validation fails;throw error
    //send data

    try {
        var res = await axiosInstance.post('/transaction', game_data, getToken());
        return res.data;
    } catch (error) {
        if(error.response.status !== 401){
            save_to_local_storage(game_data);
        }
        checkStatusAndRefreshPage(error);
        console.log(error);
        throw error;
        //return error.response.data;
    }
}

//if transaction failed during
const save_to_local_storage = async (game_data) => {
    let transactions = JSON.parse(localStorage.getItem('_ot_')) || [];
    transactions.push(game_data)
    await localStorage.setItem('_ot_',JSON.stringify(transactions));
}
const remove_from_local_storage = async(trx_id)=>{
    let transactions = JSON.parse(localStorage.getItem('_ot')) || [];
    transactions = transactions.filter(t=>t.trx_id !== trx_id);
    localStorage.setItem('_ot_',JSON.stringify(transactions));
}

const sync_transaction_with_server = async () => {

    let transactions = JSON.parse(localStorage.getItem('_ot')) || [];
    
    transactions.forEach(async transaction => {
        try {
            var res = await axiosInstance.post('/transaction', transaction, getToken());
            if(res.status === 200){
                remove_from_local_storage(transaction.trx_id)
            }
        } 
        catch (error) {

        }
    });
}

const void_transaction = async(trx_id)=>{
    try {
        console.log(trx_id);
        const trx_data = {trxId:trx_id};
        var res = await axiosInstance.post('/transaction/void',trx_data,getToken());
        return res.data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        throw error;
    }
}

const update_transaction = async(trx_data)=>{
    try {
        console.log(trx_data);
        var res = await axiosInstance.put('/transaction',trx_data,getToken());
        return res.data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        throw error;
    }
}

const checkStatusAndRefreshPage = async(error)=>{
    try {
        if(error.response.status === 401){
            window.location.reload();
            localStorage.removeItem('bingo_user_info');
            localStorage.removeItem('bingo_access_token');
        }
    } catch (error) {
        
    }
}

const gamePlayService = { create_winning_transaction,void_transaction,update_transaction };
export default gamePlayService;