import React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, TextField, Dialog, Container, Typography, Divider,MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import NumberEntry from './CardEntry';
import { useDispatch,useSelector } from 'react-redux';
import { create_boards } from 'src/redux/slices/boardSlice';
import { useEffect } from 'react';
import { get_all_companies } from 'src/redux/slices/companySlice';
import { reset_board_saved } from 'src/redux/slices/boardSlice';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

function CardEntryForm({open,onClose}) {
  const [descriptionError, setDescriptionError] = useState(false);
  const [board_id, setBoardID] = useState('');
  const [forBranch,setForBranch] = useState('');
  const [forBranchError,setForBranchError] = useState('');

  const companies = useSelector((state) => state.companies.companies);


  const dispatch = useDispatch();

   // Initialize a 5x5 array of numbers
   const [numbers, setNumbers] = useState(Array.from({ length: 5 }, () => Array(5).fill('')));

   // Handle input change for a given row and column
   const handleChange = (row, col) => (e) => {
     // Get the input value as a number

     let value = Number(e.target.value);
 
     if (value > 75) {
        value = 75;
     }
     if (value <= 0) {
       value = null;
     }
 
     // Update the numbers array with the new value
     setNumbers((prevNumbers) => {
       // Copy the previous array
       const newNumbers = [...prevNumbers];
 
       // Update the value at row and col
       newNumbers[row][col] = value;
 
       // Return the new array
       return newNumbers;
     });
     
   };

  

  const handleSaveBoardClick = async () => {
    //update board saved state first here
    dispatch(reset_board_saved());

   
    var flatten = numbers.flat();

    if (board_id == null || board_id.length == 0) {
      alert('Please Enter Board ID!');
      return;
    }

     //validate the numbers
    //check for duplicates,empty and out of range elements

    if (flatten.includes('') || flatten.includes(null)) {
      alert('board contains empty items');
      return;
    }
    const dups = flatten.filter((item, index) => flatten.indexOf(item) !== index);
    if (dups.length > 0) {
      alert('board contains duplicated items: ' + dups);
      return;
    }
    const outOfRange = flatten.filter((x) => x > 75 && x < 1);
    if (outOfRange.length > 0) {
      alert('(HOW?HOW?)board contains out of range items: ' + outOfRange);
      return;
    }

    let arrayOfObj = [];
    arrayOfObj.push(numbers);
    const params = { boards: arrayOfObj, board_id: board_id, branch: forBranch };
    dispatch(create_boards(params));

    
  };

  const handleDialogClose = () => {};

  const handleClearBoardClick = () =>{
    setBoardID('');
    setNumbers(Array.from({ length: 5 }, () => Array(5).fill('')));
  }

  useEffect(() => {
   
    async function fetchCompanies() {
      try {
        dispatch(get_all_companies());
      } catch (error) {
        console.log(error);
      }
    }
    if (!companies || companies.length == 0) {
      fetchCompanies();
    }
  }, []);

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography sx={{  color: '#ad0000' }} variant="h4" gutterBottom>
              Create New Board
            </Typography>

            <Divider sx={{ my: 3 }}>
              <Typography sx={{color:'#ad0000'}} variant="body2">
                manual board entry
              </Typography>
            </Divider>

            {/*Start Registration Form*/}
            <Stack spacing={3}>
              <TextField
                error={descriptionError}
                name="description"
                label="Board ID"
                type='number'
                value={board_id}
                onChange={(e) => setBoardID(e.target.value)}
              />
              <TextField
                error={forBranchError}
                select
                name="forBranch"
                label="For Branch"
                value={forBranch}
                onChange={(e) => setForBranch(e.target.value)}
              >
                <MenuItem key={null} value={'NONE'}></MenuItem>
                {companies[0]?.branches?.map((branch) => {
                  return (
                    <MenuItem key={branch.branch_name} value={branch.branch_name}>
                      {branch.branch_name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <NumberEntry numbers={numbers} handleChange={handleChange} />

              <Stack direction={'row'} spacing={3}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSaveBoardClick}>
                  Save
                </LoadingButton>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClearBoardClick}>
                  Clear
                </LoadingButton>
              </Stack>
            </Stack>
          </StyledContent>
        </Container>
      </Dialog>
    </>
  );
}

export default CardEntryForm;
