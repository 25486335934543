import React from 'react';
import { styled } from '@mui/material/styles';

const NumberBox = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#fff',
  borderRadius: '5px',
  padding: '20px',
  fontSize: '150%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#000',
  fontWeight: 'bold',
  fontSize: '24px',
  border: '1px solid gray',
  
}));
const BingoBoxWrapper = styled('div')(({ theme }) => ({
  width: '600px',
  display: 'grid',
  gridGap: '5px',
  gridTemplateColumns: 'repeat(5, 80px)',
  gridTemplateRows: 'repeat(5, 80px)',
  gridAutoFlow: 'column',

  padding: '4px',
  borderRadius: '10px',
  alignContent: 'center',
  justifyContent: 'center',
}));






function ResultBingoGrid({ winning_info,manual_trx_save }) {
  //console.log(winning_info);
  return winning_info?.board ? (
    <BingoBoxWrapper>
      {winning_info?.board.map((item,outIndex) => {
        return item.map((i,inIndex) => {
         
          //if it is manual trx do not check result from winning result
            if(!manual_trx_save && winning_info?.is_winner && winning_info?.winning_pattern.includes(i))
            {
              if(inIndex == 2 && outIndex == 2){
                return <NumberBox style={{background:'red',color:'white',fontSize:'18px'}}>{inIndex == 2 && outIndex == 2 ? 'ነጻ' : i}</NumberBox>;
              }else{
                return <NumberBox className='winnerClass' style={{
                  background:'blue',color:'#fff',
                }}>
                  {i}
                </NumberBox>;
              }
            }
            
            else if((manual_trx_save && winning_info?.call_list.includes(i)) || (!winning_info?.is_winner && winning_info?.call_list.includes(i)))
            {
              if(inIndex == 2 && outIndex == 2){
                return <NumberBox style={{background:'blue',color:'white',fontSize:'18px'}}>{inIndex == 2 && outIndex == 2 ? 'ነጻ' : i}</NumberBox>;
              }else if(manual_trx_save){
                return <NumberBox style={{background:'#8bfc21'}}>{i}</NumberBox>;
              }
              else{
                return <NumberBox style={{background:'red'}}>{i}</NumberBox>;
              }
            }
            else
              return <NumberBox >{i}</NumberBox>;
        });
      })}
    </BingoBoxWrapper>
  ) : (
    <h4>No board/cartela information found!</h4>
  );
}

export default ResultBingoGrid;
