import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
//import USERLIST from '../_mock/user';

import { useSelector,useDispatch } from 'react-redux';
import { get_transactions_by_criteria } from 'src/redux/slices/transactionSlice';
import SalesFindPanel from './SalesFindPanel';
import { get_credit_for_user } from 'src/redux/slices/creditSlice';

// ----------------------------------------------------------------------

const currency_formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const INITIAL_TABLE_HEAD = [
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'bet', label: 'Bet', alignRight: false },
  { id: 'players', label: 'Player#', alignRight: false },
  { id: 'totalWinings', label: 'Total Won', alignRight: false },
  { id: 'gameType', label: 'Game Type', alignRight: false },
  { id: 'cut', label: 'Cut', alignRight: false },
  { id: 'playerWinings', label: 'Player Won', alignRight: false },
  { id: 'branch', label: 'Branch', alignRight: false },
  { id: 'call_list', label: '#Call', alignRight: false },
  { id: 'winning_board_id', label: 'Winners', alignRight: false },
  { id: 'cashier', label: 'Cashier', alignRight: false },
  
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  
  const stabilizedThis = array.map((el, index) => [el, index]);
  
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => _user.cashier.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function SalesPage() {
  const user = useSelector((state) => state.auth.user);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [openDialog,setOpenDialog] = useState(false);

  const [TRX_LIST,setBindData] = useState([]);

  const [startDate,setStartDate] = useState(new Date().now);
  const [endDate,setEndDate] = useState(new Date().now);
  
  
  
  const trx_lists = useSelector(state=>state.transactionList.transaction_list);
  const is_trx_loading = useSelector(state=>state.transactionList.transaction_loading);
  const trx_load_erro = useSelector(state=>state.transactionList.transaction_load_error);

  const _credit = useSelector(state=>state.credits.credit);


const TABLE_HEAD = (user?.role.toLowerCase() === 'super' || user?.role.toLowerCase() === 'root')
    ? [...INITIAL_TABLE_HEAD, { id: 'location', label: 'Location', alignRight: false }]
    : INITIAL_TABLE_HEAD;

    if (!TABLE_HEAD.some(item => item.id === '')) {
      TABLE_HEAD.push({ id: '' });
    }

  const dispatch = useDispatch();


  const current_date = ()=>{
    var curr = new Date();
    console.log(curr);
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substring(0,10);
    return date;
  }

  useEffect(()=>{
    async function fetchSales(){//fetch sales
      try {
        var isEmp = false;
        var isSupper = false;

        if(user?.role.toLowerCase() === 'employee'){
          isEmp = true;
        }

        if(user?.role.toLowerCase() === 'super' || user?.role.toLowerCase() === 'root'){
          isSupper = true;
        }
        const criteria = {
          startDate : current_date(),
          endDate: current_date(),
          cashier: isEmp ? user?.userName : null, //cashiers can only see their own transactions
          company_id : isSupper ? null : user?.company_id,
        };

        //dispatch(get_all_transactions());
        dispatch(get_transactions_by_criteria(criteria));
      } catch (error) {
        console.log(error)
      }
    }
    fetchSales();
  },[]);


  useEffect(()=>{
    function prepareData(){
     try {
      //console.log('data binding started?');
      setBindData(
        trx_lists && trx_lists.map((data, index) => ({
          id: data.id,
          //date: new Date(data.trx_date).toISOString().split('T')[0],
          date: new Date(data.trx_date).toLocaleString(),
          bet: data.bet_amount,
          players:data.number_of_players,
          totalWinings: data.total_winning,
          gameType: data.owner_cut_percentage,
          cut: data.owner_cut_amount,
          playerWinings: data.player_winning,
          branch:data.branch,
          call_list:data.call_list,
          winning_board_id:data.winning_board_id,
          cashier:data.cashier,
          is_void:data.is_void || false,
          _lat:data.lat ? data.lat : 0,
          _lon:data.lon ? data.lon : 0
        }))
        );
     } catch (error) {
      console.log(error)
     }
    };
    prepareData();
  },[trx_lists]);//sales data

  useEffect(()=>{
    dispatch(get_credit_for_user(user?.phone));
  },[]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  }


  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = TRX_LIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TRX_LIST.length) : 0;

  const filteredTrxs = applySortFilter(TRX_LIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredTrxs.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Sales |  ሀሁ ቢንጎ </title>
      </Helmet>
      <Container>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{color: 'maroon'}} gutterBottom>
            Sales<span style={_credit && _credit.remaining_credit > 50 ? { color: 'green' } : { color: 'red' }}>
                  {`   (${_credit ? currency_formatter.format(_credit.remaining_credit) : 0} Birr)`}
                </span>
          </Typography>

          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Sales Detail
          </Button> */}
        </Stack>
        <SalesFindPanel/>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filter_place_holder={"Search By Cashier"}
            isSales={true}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={TRX_LIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredTrxs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, date, bet, players, totalWinings, gameType, cut, playerWinings, branch, call_list,winning_board_id,cashier,is_void,_lat,_lon } = row;
                    const selectedId = selected.indexOf(id) !== -1;

                    return (
                      <TableRow style={is_void ? {backgroundColor:'#b3cdd1',color:'white'}: {}} hover key={id} tabIndex={-1} role="checkbox" selected={selectedId}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedId} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {date}
                            </Typography>
                          </Stack>
                        </TableCell>

                        {/* <TableCell align="left">{date}</TableCell> */}

                        <TableCell align="left">{currency_formatter.format(bet)}</TableCell>
                        <TableCell align="left">{players}</TableCell>
                        <TableCell align="left">{currency_formatter.format(totalWinings)}</TableCell>
                        <TableCell align="left">{gameType}</TableCell>
                        <TableCell align="left">
                          <span style={{ color: 'blueviolet', fontWeight: 'bold' }}>
                            {currency_formatter.format(cut)}
                          </span>
                        </TableCell>
                        <TableCell align="left">{currency_formatter.format(playerWinings)}</TableCell>
                        <TableCell align="left">{branch}</TableCell>
                        <TableCell align="left">{call_list}</TableCell>
                        <TableCell align="left">{winning_board_id?.join(',')}</TableCell>
                        <TableCell align="left">
                          <Typography style={{ color: 'green', fontWeight: 'Bold' }} variant="subtitle3" noWrap>
                            {cashier}
                          </Typography>
                        </TableCell>
                        {(user?.role.toLowerCase() === 'super' || user?.role.toLowerCase() === 'root') && <TableCell align="left">{`${_lat},${_lon}`}</TableCell>} 
                        

                        {/* <TableCell align="left"> 
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={TRX_LIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
    </>
  );
}
