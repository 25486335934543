import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import React, { useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  SwipeableDrawer,
  Stack,
  TextField,
  MenuItem,
} from '@mui/material';

// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

import { set_dynamic_pattern_one,set_dynamic_pattern_two,reset_dynamic_pattern, set_pattern_operator } from 'src/redux/slices/gamePlaySlice';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// ----------------------------------------------------------------------
import { styled } from '@mui/material/styles';

const NumberBox = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#fff',
  borderRadius: '5px',
  padding: '10px',
  fontSize: '150%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#000',
  fontWeight: 'bold',
  fontSize: '16px',
  border: '1px solid gray',
  
}));
const BingoBoxWrapper = styled('div')(({ theme }) => ({
  width: '450px',
  display: 'grid',
  gridGap: '5px',
  gridTemplateColumns: 'repeat(5, 60px)',
  gridTemplateRows: 'repeat(5, 60px)',
  gridAutoFlow: 'column',

  padding: '2px',
  borderRadius: '10px',
  alignContent: 'center',
  justifyContent: 'center',
}));


const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: 'Your order is placed',
    description: 'waiting for shipping',
    avatar: null,
    type: 'chat_message',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.fullName(),
    description: 'answered to your comment on the MinimalJ',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Bitch You have new messageX',
    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new mail',
    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Delivery processing',
    description: 'Your order is being shipped',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];

export default function NotificationsPopover() {
  
  //const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const [notifications, setNotifications] = useState([]);

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const [open, setOpen] = useState(null);

  const dispatch = useDispatch();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const [openDrawer,setOpenDrawer] = useState(false);

  const toggleDrawer = (open) => {
    setOpenDrawer(open);
  }

  

  const notificationsList = useSelector(state=>state.appState._notifications);
  const user = useSelector((state) => state.auth.user);
 

  useEffect(()=>{
    /*if(user && user.role === "Admin"){
      dispatch(get_notifications(user.company_id));
    }else if(user && user.role === "Agent"){
      dispatch(get_notifications(user.userName));
    }*/
    dispatch(get_notifications(user.phone));
    
  },[]);

  useEffect(()=>{
    function prepareNotificationData(){
      const sortedNotifications = notificationsList && notificationsList.slice().sort((a, b) => b.timeStamp - a.timeStamp);
      try {
        setNotifications(
          sortedNotifications && sortedNotifications.map((data,index)=>({
            id:data._id,
            title:data.type,
            description:data.message,
            avatar:null,
            type:'chat_message',
            createdAt: new Date(data.timeStamp),//sub(new Date(), { milliseconds: new Date() - new Date(data.timeStamp) }),
            isUnRead:!data.seen
          }))
        );
      } catch (error) {
        console.log(error);
      }
    }
    prepareNotificationData();
  },[notificationsList]);

  return (
    <>
      <RightOptionsDrawer open={openDrawer} toggleDrawer={toggleDrawer} />
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="ant-design:notification-outlined" />
        </Badge>
      </IconButton>

      {/* pattern drawer */}
      <IconButton
        color={openDrawer ? 'primary' : 'default'}
        onClick={() => toggleDrawer(true)}
        sx={{ width: 40, height: 40 }}
      >
        <Iconify icon="tdesign:letters-p" style={{color: '#500202'}} />
        
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications
              .filter((t) => t.isUnRead)
              .map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications
              .filter((t) => !t.isUnRead)
              .map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            *
          </Button>
        </Box>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);
  
  const disptach = useDispatch();

  const handle_read_status = (id)=>{
    if(notification && notification.isUnRead){
      disptach(set_notification_seen(id));
    }
    
  }
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => handle_read_status(notification.id)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}

const pattern_operators = [
  {op_name:'AND'},
  {op_name:'OR'}
]

const preset_patterns = [
  {pattern_id:null,pattern_name:'Default'},
  {pattern_id:140,pattern_name:'Any Vertical'},
  {pattern_id:141,pattern_name:'Any Horizontal'},
  {pattern_id:142,pattern_name:'Any 2 Vertical'},
  {pattern_id:143,pattern_name:'Any 2 Horizontal'},
  {pattern_id:144,pattern_name:'Any Diagonal'},
  {pattern_id:145,pattern_name:'Any 1 Corner Square'},
  {pattern_id:146,pattern_name:'Any 2 Corner Square'},
  {pattern_id:147,pattern_name:'Any 3 Corner Square'},
  {pattern_id:148,pattern_name:'All 4 Corner Square'},
  {pattern_id:149,pattern_name:'Any 2 Lines'},
  {pattern_id:150,pattern_name:'Any 3 Lines'},
  {pattern_id:151,pattern_name:'All 4 Corner Square (Single)'},
  {pattern_id:152,pattern_name:'Any Line'},
  {pattern_id:153,pattern_name:'4 Single Middle'},
]

const preset_pattern_indicators = [
  { id: 140, pattern: [10, 11, 12, 13, 14] },
  { id: 142, pattern: [5, 6, 7, 8, 9, 15, 16, 17, 18, 19] },
  { id: 141, pattern: [2, 7, 12, 17, 22] },
  { id: 143, pattern: [1, 6, 11, 16, 21, 3, 8, 13, 18, 23] },

  { id: 144, pattern: [0, 6, 12, 18, 24, 4, 8, 12, 16, 20] },
  { id: 145, pattern: [3, 8, 4, 9] },
  { id: 146, pattern: [3, 8, 4, 9, 15, 16, 20, 21] },
  { id: 147, pattern: [3, 8, 4, 9, 15, 16, 20, 21, 0, 1, 5, 6] },
  { id: 148, pattern: [3, 8, 4, 9, 15, 16, 20, 21, 0, 1, 5, 6, 18, 23, 24, 19] },
  { id: 149, pattern: [0, 6, 12, 18, 24, 4, 8, 12, 16, 20, 10, 11, 12, 13, 14, 2, 7, 12, 17, 22] },
  { id: 150, pattern: [0, 1, 2, 3, 4, 6, 12, 18, 24, 9, 14, 19] },
  { id: 151, pattern: [0, 4, 20, 24] },
  { id: 152, pattern: [0, 6, 12, 18, 24, 4, 8, 12, 16, 20, 10, 11, 12, 13, 14, 2, 7, 12, 17, 22] },
  { id: 153, pattern: [6,8,16,18] },
];

import { get_notifications, set_notification_seen } from 'src/redux/slices/appStateSlice';

function RightOptionsDrawer({open,toggleDrawer}){

const [pattern1,setPattern1] = useState(null);//type 1
const [pattern2,setPattern2] = useState(null);//type 2

const [operatorValue,setOperatorValue] = useState('AND')

const [pattern1Draw,setPattern1Draw] = useState([]);
const [pattern2Draw,setPattern2Draw] = useState([]);

const dispatch = useDispatch();

const handlePatternClear = () => {
  setPattern1(null);
  setPattern2(null);
  setPattern1Draw([]);
  setPattern2Draw([]);
  dispatch(reset_dynamic_pattern());
}

const handlePatternOperatorChange = (e)=>{
    setOperatorValue(e.target.value);
    if(e.target.value === 'OR')
      dispatch(set_pattern_operator(true))
    else
      dispatch(set_pattern_operator(false))
}

const handlePatternOneChange = (e) => {

  const newValue = e.target.value;
  if(!newValue){
    handlePatternClear();
    return;
  }
  
  const selectedPattern = preset_pattern_indicators.find(item => item.id === parseInt(newValue))?.pattern;
  setPattern1(newValue);
  setPattern1Draw([]);
  if (selectedPattern) {
    setPattern1Draw(prevPatternDraw => [...prevPatternDraw, ...selectedPattern]);
  }
  
  dispatch(set_dynamic_pattern_one(e.target.value));
};

const handlePatternTwoChange = (e) => {
  const newValue = e.target.value;
  if(!newValue){
    handlePatternClear();
    return;
  }
  const selectedPattern = preset_pattern_indicators.find(item => item.id === parseInt(newValue))?.pattern;
  setPattern2(newValue);
  setPattern2Draw([]);
  if (selectedPattern) {
    setPattern2Draw(prevPatternDraw => [...prevPatternDraw, ...selectedPattern]);
  }
  dispatch(set_dynamic_pattern_two(e.target.value));
};


const boxes = Array.from({ length: 25 });


  return (
    <div>
      <React.Fragment key={'right'}>
        <SwipeableDrawer anchor="right" open={open} onClose={() => toggleDrawer(false)} onOpen={true}>
          <Box sx={{ width: 550, backgroundColor: '#fff', padding: '20px' }}>
            <Stack spacing={3} direction={'column'}>
              <TextField
                sx={{
                  label: { color: '#801313', '&:focus' : {color: '#801313'} },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#801313', 
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#801313' 
                  },
                  '& .MuiOutlinedInput-input': {
                    color: '#801313'  
                  },
                  '& .MuiSelect-icon': {
                    color: '#801313'  
                  }
                }}
                labelId="Primary_Pattern"
                select
                value={pattern1}
                label="Primary Pattern"
                onChange={handlePatternOneChange}
                fullWidth
              >
                {preset_patterns?.map((pattern) => {
                  return (
                    <MenuItem key={pattern.pattern_id} value={pattern.pattern_id}>
                      {pattern.pattern_name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <TextField
                sx={{
                  label: { color: '#801313', '&:focus' : {color: '#801313'} },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#801313', 
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#801313' 
                  },
                  '& .MuiOutlinedInput-input': {
                    color: '#801313'  
                  },
                  '& .MuiSelect-icon': {
                    color: '#801313'  
                  }
                }}
                labelId="pattern_operator"
                select
                value={operatorValue}
                label="Pattern Operator"
                onChange={handlePatternOperatorChange}
                fullWidth
              >
                {pattern_operators?.map((oops) => {
                  return (
                    <MenuItem key={oops.op_name} value={oops.op_name}>
                      {oops.op_name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <TextField
                sx={{
                  label: { color: '#801313', '&:focus' : {color: 'white'} },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#801313', 
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#801313' 
                  },
                  '& .MuiOutlinedInput-input': {
                    color: '#801313'  
                  },
                  '& .MuiSelect-icon': {
                    color: '#801313'  
                  }
                }}
                labelId="Secondary_Pattern"
                select
                value={pattern2}
                label="Secondary Pattern"
                onChange={handlePatternTwoChange}
                fullWidth
              >
                {preset_patterns?.map((pattern) => {
                  return (
                    <MenuItem key={pattern.pattern_id} value={pattern.pattern_id}>
                      {pattern.pattern_name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Button style={{background:'#801313',color:'#fff'}} onClick={handlePatternClear}>Clear Patterns</Button>
            <BingoBoxWrapper>
              {boxes.map((box,index)=>{
                if([...new Set([...pattern1Draw,...pattern2Draw])]?.includes(index)){
                  return <NumberBox style={{background:'#801313'}} key={index}></NumberBox>
                }
                return <NumberBox key={index}></NumberBox>
              })}
            </BingoBoxWrapper>
            </Stack>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
