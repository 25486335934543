import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import notificationsService from "src/services/notificationService";

const initialState = {
    menu_collapsed:false,
    _notifications:[],
    notificationCreated:null,
    notificationCreationFailed:null,
}

//create notifications

export const create_notification = createAsyncThunk(
    'createNotification',
    async(notificationObj,thunkAPI)=>{
            try {
                const result= await notificationsService.create_notification(notificationObj);
                return result;
            } catch (error) {
                return thunkAPI.rejectWithValue(error);
            }
    }
);

export const get_notifications = createAsyncThunk(
    'getNotification',
    async(company_id,thunkAPI)=>{
        try {
            console.log('from fetch notification thunk');
            const result= await notificationsService.get_notification(company_id);
            return result;
        } catch (error) {
            
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const set_notification_seen = createAsyncThunk(
    'setNotificationSeen',
    async(id,thunkAPI)=>{
        try {
            console.log('set ntofication seen');
            const result= await notificationsService.set_notification_seen(id);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

//get notifications


export const appStateSlice = createSlice({
    name:'appState',
    initialState,
    reducers:{
        setMenueCollapsed:(state,action)=>{
            state.menu_collapsed = !state.menu_collapsed;
        }
    },
    extraReducers:{
        [create_notification.fulfilled]:(state,action)=>{
            state.notificationCreated = true;
        },
        [create_notification.pending]:(state,action)=>{
            
        },
        [create_notification.rejected]:(state,action)=>{
            state.notificationCreationFailed = false;
        },
        [get_notifications.fulfilled]:(state,action)=>{
            //console.log('notification fetch !')
            //console.log(action.payload.data);
            state._notifications = action.payload.data;
        },
        [get_notifications.pending]:(state,action)=>{
            //console.log('get notification pending')
        },
        [get_notifications.rejected]:(state,action)=>{
            //console.log('get notification rejected');
        },
        [set_notification_seen.fulfilled]:(state,action)=>{
            
            console.log(action.payload.data);
            if(action.payload.data){
                const {_id} = action.payload.data;
                const index = state._notifications.findIndex(item=>item._id === _id);
                
                if(index !== - 1){
                    state._notifications[index].seen = true;
                }else{
                    
                }
            }
        },
        [set_notification_seen.pending]:(state,action)=>{
            console.log('seen pending')
        },
        [set_notification_seen.rejected]:(state,action)=>{
            console.log('seen rejected');
        }
    }
});

export const {setMenueCollapsed} = appStateSlice.actions;
const {reducer} = appStateSlice;
export default reducer;