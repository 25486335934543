import React from 'react'
import {styled} from '@mui/material/styles'
import { useSelector } from 'react-redux';

const StyledContainer = styled('div')(({ theme }) => ({
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));
const StyledTable = styled('div')(({ theme }) => ({
    padding:'4px',
    borderRadius:'10px'
}));
const StyledRow = styled('div')(({ theme }) => ({


    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#212B36',
    fontSize: '14px',
    padding: '2px',

}));

const StyledAmount = styled('div')(({theme }) => ({
    alignSelf: 'flex-end',
    color: '#1a1a1d',
    marginLeft: '18px',
    fontWeight:'bold'
}));

const currency_formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

function SalesDetail() {
    const trx_lists = useSelector(state=>state.transactionList.transaction_list);
    return (
      <StyledContainer>
        <StyledTable>
          <StyledRow>
            <div style={{ color: '#ad0000' }}>Total Winnings: </div>
            <StyledAmount>
              {trx_lists ? currency_formatter.format(trx_lists.filter(t=>t.is_void == null || !t.is_void).map((item) => item.total_winning).reduce((prev, next) => prev + next,0)) : 0}
            </StyledAmount>
          </StyledRow>
          <StyledRow>
            <div style={{ color: '#ad0000' }}>Total Cut:</div>
            <StyledAmount>
              {trx_lists ? currency_formatter.format(trx_lists.filter(t=>t.is_void == null || !t.is_void).map((item) => item.owner_cut_amount).reduce((prev, next) => prev + next,0)) : 0}
              </StyledAmount>
          </StyledRow>
          <StyledRow>
            <div style={{ color: '#ad0000', }}>Total Transactions:</div>
            <StyledAmount>{trx_lists ? trx_lists.length : 0}</StyledAmount>
          </StyledRow>
        </StyledTable>
      </StyledContainer>
    );
}

export default SalesDetail