import axios from 'axios'

// const API_URL = 'http://localhost:5000/api'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });


const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}

const credit_transfer = async (transferObj) =>{
    try {
        const data = await axiosInstance.post('/credit',transferObj,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}
const get_credit_for_user = async(phone)=>{
    try {
        if(phone && phone !== undefined){
            const data = await axiosInstance.get(`/credit/credit/${phone}`,getToken());
            return data;
        }
    } catch (error) {
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}

const get_trx_history_and_credit = async(phone,fetchAll)=>{
    try {
        const data = await axiosInstance.get(`/credit/transactionHistoryCredit/${phone}/${fetchAll}`,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}

const get_trx_history_by_condition = async(reqObj)=>{
    try {
        const data = await axiosInstance.post('/credit/transactionHistoryCondition/',reqObj,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}




const get_trx_history = async(phone,fetchAll)=>{
    try {
        const data = await axiosInstance.get(`/credit/transactionHistory/${phone}/${fetchAll}`,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}

const checkStatusAndRefreshPage = async(error)=>{
    try {
        if(error.response.status === 401){
            window.location.reload();
            localStorage.removeItem('bingo_user_info');
            localStorage.removeItem('bingo_access_token');
        }
    } catch (error) {
        
    }
}


const creditService = {
    credit_transfer,
    get_credit_for_user,
    get_trx_history,
    get_trx_history_and_credit,
    get_trx_history_by_condition
};

export default creditService;