import './sass/cardselector.css';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addOrRemoveSelectedBoard } from 'src/redux/slices/boardSlice';

function CardSelector({ selected }) {

  const selectedBoards = useSelector((state) => state.boards.selected_board_ids);
  const all_boards = useSelector((state) => state.boards.available_playing_boards);

  const GetBoardItem = () => {
    
    let boards = [];
    all_boards?.map((x) => {
      
      boards.push(
        <BoardCard
          key={x.id}
          board_id={x.board_id}
          board_name={x.board_name}
          isCardSelected={selectedBoards.includes(x.board_id)}
        />
      );
    });

    return boards;
  };

  const GetSelectedBoardItem = () => {
    
    let boards = [];
    selectedBoards?.map((x) => {
      boards.push(
        <BoardCard
          key={x}
          board_id={x}
          board_name={x}
          isCardSelected={selectedBoards.includes(x.board_id)}
        />
      );
    });

    return boards;
  };

  return <div className="board-container">{selected ? GetSelectedBoardItem() : GetBoardItem()}</div>;
}

export default CardSelector;

function BoardCard({ key, board_id, board_name, isCardSelected }) {

  const selectedBoards = useSelector((state) => state.boards.selected_board_ids);
  //const [cardSelected, setCardSelected] = useState(isCardSelected);
  const dispatch = useDispatch();

  const handleCardSelected = (id) => {
    //setCardSelected(!cardSelected);
    dispatch(addOrRemoveSelectedBoard(id));
  };

  return (
    <div key={key} className="card-display" onClick={() => handleCardSelected(board_id)}>
      <div className="card-content">
        <div className={(selectedBoards.includes(board_id)) ? 'card-detail-content card-selected' : 'card-detail-content'}>
          <div className="card-id">{board_id}</div>
          <div className="card-name">{board_name}</div>
        </div>
      </div>
    </div>
  );
}
