import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

import boardService from "src/services/boardService";

const initialState = {
    boards:[],
    selected_board:null,
    winning_info:null,
    selected_board_ids:[],
    disqualified_board_ids:[],
    available_playing_boards:[],
    error:'',
    board_saved:null,
    board_save_has_error:null,
    sync_status:null,
    
}

export const sync_boards = createAsyncThunk(
    'syncBoards',
    async(compId,thunkAPI) => {
        try {
            const result = await boardService.sync_boards(compId);
            return result;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const create_boards = createAsyncThunk(
    'createBoards',
    async(params,thunkAPI)=>{
        try{
            const result = await boardService.create_cartela(params);
            return {boards:result};
        }catch(err){
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const get_board_by_id = createAsyncThunk(
    'getBoardById',
    async(id,thunkAPI)=>{
        try {
            const result = await boardService.get_board_by_id(id);
            return {board:result.data};
        } catch (err) {   
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const get_all_boards = createAsyncThunk(
    'getAllBoards',
    async(selectedBranch,thunkAPI)=>{
        try {
            const result = await boardService.get_all_boards(selectedBranch);
            return {boards:result.data};
        } catch (err) {   
            console.log(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const check_winning = createAsyncThunk(
    'checkWinning',
    async(params,thunkAPI)=>{
        try {
            //console.log(params);
            const result = await boardService.check_winning(params.id,params.call_list,params.pattern,params.patternId1,params.patternId2,params.refundPattern,params.isOrOp);
            return result.data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const delete_board_by_id = createAsyncThunk(
    'delete_board_by_id',
    async(id,thunkAPI)=>{
        try {
            const result = await boardService.delete_board_by_id(id);
            console.log(result.data);
            return result.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const boardSlice = createSlice({
    name:'boards',
    initialState,
    reducers:{
        addOrRemoveSelectedBoard: (state,action)=>{
            if(state.selected_board_ids.includes(action.payload)){
                state.selected_board_ids = state.selected_board_ids.filter(x=>x!=action.payload);
            }else{
                state.selected_board_ids.push(action.payload);
            }
        },
        resetSelectedBoardIds:(state,action)=>{
            state.selected_board_ids = [];
            state.disqualified_board_ids = [];
        },
        disqualify_player:(state,action)=>{
            state.selected_board_ids = state.selected_board_ids.filter(t=>t != action.payload);
            state.disqualified_board_ids.push(action.payload);
        },
        reset_winning_info:(state,action)=>{
            state.winning_info = null;
            state.selected_board = null;
            state.selected_board_ids = [];
            state.disqualified_board_ids = [];
        },
        reset_board_saved:(state,action)=>{
           // if(state.board_saved){
                state.board_saved = null;
                state.board_save_has_error = null;
           // }
        },
        include_disqualified_boards_on_restart:(state,action)=>{
            console.log(action.payload);
            if (action.payload && action.payload.length > 0) {
                const numbersArray = action.payload.map(item => Number(item));
                state.selected_board_ids.push(...numbersArray);
            }
            console.log(state.selected_board_ids)
        },
    },
    extraReducers:{
        [sync_boards.fulfilled]:(state,action)=>{
            state.sync_status = "sync complete!";
        },
        [sync_boards.pending]:(state,action)=>{
            state.sync_status = "sync in progress ...";
        },
        [sync_boards.rejected]:(state,action)=>{
            state.sync_status = "sync error!";
        },
        [create_boards.fulfilled]:(state,action)=>{
            state.boards = action.payload.boards;
            state.error='';
            state.board_saved = true;
            state.board_save_has_error = null;
        },
        [create_boards.rejected]:(state,action)=>{
            state.error = action.payload.error;
            state.board_saved = null;
            state.board_save_has_error = true;
        },
        [get_board_by_id.fulfilled]:(state,action)=>{
            state.selected_board = action.payload.data.board;
        },
        [get_board_by_id.rejected]:(state,action)=>{
            state.error = action.payload.data.error;
        },
        [get_all_boards.fulfilled]:(state,action)=>{
            state.boards = action.payload.boards.data;
            state.available_playing_boards = action.payload.boards.data;
        },
        [get_all_boards.pending]:(state,action)=>{
            console.log('getting all boards ...');
        },
        [get_all_boards.rejected]:(state,action)=>{
            state.error = action.payload;
        },
        [check_winning.fulfilled]:(state,action)=>{
            state.winning_info = action.payload.data;
        },
        [check_winning.rejected]:(state,action)=>{
            state.error = action.payload.data;
        },
        [delete_board_by_id.fulfilled]:(state,action) => {

            //this is either action.payload.data or action.payload.data._id//
            console.log(action.payload.data);
            state.boards = state.boards.filter(x=>x._id !== action.payload.data);
            console.log(state.available_playing_boards);
            state.available_playing_boards = state.available_playing_boards?.filter(x=>x._id !== action.payload.data);
        },
        [delete_board_by_id.rejected]:(state,action) => {
            console.log(action.payload);
        }
    }
});

export const {
    addOrRemoveSelectedBoard,
    resetSelectedBoardIds,
    disqualify_player,
    reset_winning_info,
    reset_board_saved,
    include_disqualified_boards_on_restart
} = boardSlice.actions;

const {reducer} = boardSlice;
export default reducer;